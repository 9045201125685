.fp{
    &__description{
        color: #222222;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        span{
            font-weight: bold;
        }
    }
    &__title{
        text-align: center;
        font-size: 26px;
        border-bottom: 1px solid #f8f9fa;;
        padding-bottom: 5px;
    }
    &__property{
        background-color:#f8f9fa;;
    }
    &__grid-wrapper{
        padding:0;
    }
    &__property-wrap{
        position: relative;
    }
    &__tranzaction-type{
        top:2%;
    }
 @media(min-width:768px){
    .fp-box{
     width:auto;
     margin:0 40px;
    }
 } 
 @media(min-width:1024px){
    .fp-box{
        width:auto;
        margin:0 10%;
        padding:20px 5%;
       }
    &__grid-type{
        top:0;
        padding-bottom: 0;
    }
    &__main-img{
        height:260px;
    }
}
}