.compare__icon-box{
    top:58%;
}
.cp-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width:100%;
    margin-bottom: 10px;
}
.cp-property-img{
    width:180px;
    height:100px;
    position: relative;
}
.cp-delete{
    width:18px;
    height:18px;
    position: absolute;
    right:10px;
    bottom:15px;
    cursor: pointer;
}
.cp-image-box{
    position: relative;
    margin: 5px;
}
@media(min-width:768px){
    .cp-property-img{
        width:250px;
        height:180px;
    }
    .cp-wrapper{
        padding-top: 20px;
    }

}
@media(min-width:1024px){
  .compare__icon-box{
      top:55%;
  }

  .cp-image-box {
    width:45%;
  }

    .cp-property-img{
        width:100%;
        max-height:100%;
        object-fit: cover;
        cursor: pointer;
    }
}
