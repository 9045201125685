.footer-homepage{
    background-color: #727376;
    padding:20px;
    font-family: Roboto;
}
.contact-icon{
    width: 15px;
    position: relative;
    top:20px;
    color:#95c41f;
}
.contact-description-text{
    line-height: 30px;
    margin:0 auto 10px;
    font-size: 13px;
    color: #fff;
    text-align: center;
    width:80%;
}
.contact-info{
    list-style-type: none;
    display: flex;
    flex-direction: column;
}
.contact-info li{
    color: #fff;
    font-size: 13px;
    line-height: 27px;
    letter-spacing: 0.5px;
    padding-left: 30px;
}
#copyright{
    text-align: center;
    margin-top:18px;
    font-size:12px;
    color:#fff;
}

.contact-description {
  margin: 0;

  a {
    color: white;
  }
}
.footer-link{
    text-decoration: none;
    color: #fff;
}
.social-box{
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    padding-bottom: 20px;
}
.footer-icon{
    cursor: pointer;
}
@media(min-width:768px){
.contact-us{
    font-size: 24px;
}
.footer-homepage{
    padding:20px 40px;
}
.footer-info{
    display: flex;
    flex-direction: row;
}
.contact-description{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-indent: 5px;
}
.contact-description-text{
    width:55%;
    margin-top: 20px;
}
.contact-info{
    margin-top: 0;
}
.contact-info li{
    font-size: 15px;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
    position: relative;
    padding-left: 30px;
    margin:5px;
}
.contact-icon{
    top:25px;
}
@media(min-width:1024px){
    .footer-homepage{
        padding:20px 10%;
    }
    .contact-description-text{
        width:30%;
        margin-top: 50px;
        text-align: justify;
    }
    .all-info{
        width:50%;
    }
    .contact-info li{
        line-height: 14px;
        letter-spacing: 0px;
    }
    .contact-icon{
        top:22px;
    }
    .social-box{
        margin-left: 4%;
        margin-top: 20px;
    }
    #copyright{
        position: relative;
        top:20px;
        padding-bottom: 40px;
    }
}
}
