.menu {
  display: none;

  @media(min-width:1024px) {
    list-style-type:none;
    display:flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-evenly;
    min-width: 70%;

    .menu-item{
      cursor: pointer;
      display: block;
      font-size: 14px;
      letter-spacing: 1px;
      color: #727376;
      font-family: Roboto;
      font-weight: bolder;
    }
    .menu-item:hover{
      color: #000;
    }
    a{
      color: #727376;
    }
    a:link{
      text-decoration: none;
      color: #727376;
    }

    a:hover{
        color: #000;
        font-weight:bolder!important;
    }


    &__wrapper {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
      box-sizing: border-box;
      background: #f5f5f5;
      height: auto;
      position: absolute;
      margin: 0;
      padding: 0;
      top: 40px;
      z-index: 11;

      &.active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .list-button{
    display: block;
    background-color: #90c923;
    cursor: pointer;
    border:none;
    height:36px;
    width:150px;
    align-items: center;
    border-radius: 3px;
    color: #fff;
    position: relative;
    bottom:5px;
    font-size: 14px;
    font-weight: bolder;
  }
  .list-button:hover{
    background-color: #8bc322;
  }
  .list-button:focus{
    outline:none;
  }
  .list-link:hover, .list-link:focus{
    font-size: initial;
    font-weight: initial!important;
    outline: none;
  }
  &__icon{
    width:16px;
    height:16px;
    position: relative;
    bottom:2px;
    cursor: pointer;
  }
  &__call-box{
    display: flex;
    flex-direction: row;
  }
  &__call-item{
    padding-left: 5px!important;
  }
  @media(min-width: 1440px) {
    .menu-item {
      padding: 0 20px;
    }
  }
}
