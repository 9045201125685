.fav-wrapper{
    width: 80%;
    float: right;
    margin-right: 0;
    z-index: 21;
    clear: both;
    overflow: hidden;
    padding-left:35px;
    top: 0;
    right: 0;
    height: 100%;
    margin-top: 0;
    background-color: transparent;
    position: fixed;
    transform: translateX(100%);
    transition: transform .3s;
    overflow-y: auto;

    &.active {
      transform: translateX(0);
    }
    .fav-inner{
        background-color: #fff;
        height:100%;
        padding:20px;
    }
    .fav-title{
        text-align: center;
        color: #222222;
        font-size: 16px;
        padding-top: 50px;
    }
    .fav-close{
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        margin-top: 15px;
    }
    .fav-see-list{
        color: #fff;
        background-color: #90c923;
        border:none;
        width:100%;
        height:35px;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
    }
    .fav-see-list:focus{
        outline: none;
    }
    .fav-see-list:hover{
        background-color: #8bc322;
    }
    .heart-icon-favs{
        width: 20px;
        height:auto;
        padding:10px;
        background-color: #90c923;
        border-radius: 5px;
        position: absolute;
        left:0;
        top:40%;
    }
    .heart-icon-fav-none{
        display: none;
    }
}
.heart-icon{
    width: 20px;
    height:auto;
    padding:10px;
    background-color: #727376;
    border-radius: 5px;
    cursor: pointer;
    z-index:10;
}
.fav__icon-box{
    position: fixed;
    top:50%;
    right:0;
    z-index: 2;
    
    h5{
        color:#fff;
        background-color: #90c923;
        border-radius: 50%;
        margin:0;
        padding:0;
        position: relative;
        right:12px;
        font-size:12px;
        text-align: center;
        bottom:48px;
        z-index:11;
        height:18px;
        width:18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media(min-width:1024px){
    .fav-wrapper{
        width:30%;
    }
}
