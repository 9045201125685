
.offer-title-color{
    color:#ffff;
}
.offer-container{
    padding:20px;
    background-color:#ffff;
    border-radius: 25px;
    position: relative;
    bottom:80px;
}

.offer-subtitle{
    color:#222222;
    font-weight: lighter;
    font-size: 16px;
    padding:20px 0;
    font-weight: 300;
    border-bottom: 1px solid #f8f9fa;;
}
.offer-img{
   width:100%;
}
@media(min-width:768px){
    .offer-container{
        padding:40px;
    }
    .offer-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
  
    .offer-img{
        width:250px;
        height:auto;
        object-fit: contain;
        margin-left: 20px;
    }
  
}
@media(min-width:1024px){
    .offer-container{
        padding:40px 20%;
    }
    .offer-top{
        width:100%;
    }
    .offer-wrapper{
      padding:40px;
    }
    
    .offer-img{
        width:40%;
        position: relative;
        bottom:100px;
    }
}