
.search-area{
    background-color:transparent;
    width:100%;
    position: relative;
    bottom:100px;
    

    &__dropdown {
      background-color: #fdfdfd;
      width: 100%;
      padding:5px;
    }
    .MuiFormControl-root{
        border:1px solid #f2f2f2;
        padding:5px;
    }
    .MuiInputBase-root{
        color:#999;
        font-size: 16px;
        font-weight: 500;
    }
    &__input{
        height:1.1875em;
        background-color: #ffff!important;
        border: 1px solid #f2f2f2!important;
    }
    &__price-form{
        display: flex;
        flex-direction: row;
    }
    &__price {
        width: 100%;

      .input-range__slider, .input-range__track--active {
        background-color: #95c41f;

      }

      .input-range__slider {
        border: 1px solid #95c41f;
      }
    }

    &__error {
      color: red;
      margin-left: 10px;
    }

    .tabs {
      display: flex;
      justify-content: center;
    }

    .tab-top-container {
      cursor: pointer;

      -webkit-transition: .2s;
      -o-transition: .2s;
      transition: .2s;
    }

    .tab-top-value {
      color: #ffffff;
      background-color: rgba(144 , 201 ,35 , 0.56);
      padding: 10px 21px 10px 25px;
      margin: 0 1px 0 1px;
      font-weight: 500;
      border-radius: 4px 4px 0 0;
      font-size: 15px;

      &.active {
        color: #000000;
        background-color: #fff;
        padding: 10px 21px 10px 25px;
        margin: 0 1px 0 1px;
        font-weight: 500;
        border-radius: 4px 4px 0 0;
      }
    }

    .id-oferta-input {
      width: 90%;
      max-height: 25px;
      border-color: #f8f9fa;
    }
}
.search-form{
    background-color: #ffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: -2px 7px 271px -40px rgb(204, 204, 204);
    z-index:5;


    .bootstrap-select *, .bootstrap-select ::after, .bootstrap-select ::before {
      box-sizing: border-box;
    }
}
.MuiTab-wrapper {
    background-color: #95c41f!important;
    padding:15px 20px;
    border-radius: 5px;
    margin:0;
    font-weight: bolder;
    color:#fff;
    opacity: 0.7;
}

.MuiTab-root{
    padding:0 2px!important;
}
.Mui-selected .MuiTab-wrapper{
    background-color: #ffff!important;
    color:#000000;
    opacity: 1;
}
.form-group{
    padding:3%;
    display: flex;
    flex-wrap: wrap;
}
.specific-search-button{
    width:100%;
    height:40px;
    margin:10px;
    font-size:14px;
}
.specific-search-button:hover{
    background-color:#8bc322;
    color:#FFFAFA;
}

.search-button {
  width: 100%;
}
.label{
    color: #222222;
    font-size: 14px;
}

.label-search {
  margin:5px 0!important;
}
.offer-box{
    background-color: #fff;
    width:100%;
    h4{
        margin-left: 5px;
    }
}
.search-area-button{
    background-color:#90c923;
    color:#ffff;
    width:100%;
    height:40px;
    margin:20px 0 0;
    font-size:14px;
    border:none;
    cursor: pointer;
    border-radius: 5px;
}
.search-area-button:hover{
    background-color: #8bc322;
}
.search-area-button:focus{
    outline:none;
}

@media(min-width:766px){
    .search-area{
        margin-top: 1%;
    }
    .specific-search-button{
        width:300px;
        height: 60px;
    }
    .form-columns{
        width:330px;
    }

    .search-button {
      width: calc(100% - 10px);
    }
}
@media(min-width:1024px){
    .search-area{
        margin: 0 auto;
        padding: 3% 8%;
        width: auto;
        bottom:90px;

        &__input{
            width:40%;
        }
        &__price-form{
            display: flex;
            flex-direction: row;


        }
        &__price{
           width:95%;

        }
        &__dropdown {
          width: 18%;
          height: auto;

          .bootstrap-select {
            width: 90%!important;

            &::after, ::before {
              box-sizing: border-box!important;
            }
          }
        }
    }
    .offer-label{
        margin-left: 5px;
    }
    .offer-box{
        margin-left: 20px;
    }
    .search-button{
        width: calc(100% - 40px);
        padding: 0 20px!important;
    }
    .form-group{
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .sub-button{
        width: 21%;
        height:44px;
        font-weight: lighter;
        position: absolute;
        left:51%;
        bottom:35px;
    }

    .MuiNativeSelect-select{
        margin-top: 5px!important;
    }
    .price-box{
        width:100%;
    }
    .price-id{
        width:50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .rent-button{
        width: 21%;
        height:44px;
        font-weight: lighter;
        position: absolute;
        left:151%;
        bottom:35px;
    }
    .search-area-button{
        width: 18%;
        height:45px;
    }
}

.bootstrap-select {
  *, ::after, ::before {
    box-sizing: content-box;
  }
}
