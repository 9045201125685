.header{
    height:55px;
    background-color:#f5f5f5;
    padding:0 10px 5px;
    display: flex;
    justify-content: space-between;
}

.logo {
  margin: auto 0;
  padding-left: 5px;
}

.logo-img{
    vertical-align: middle;
    border:0;
    height: 40px;
}
.menu{
    display: none;
}

.search {
  display: none;
}

.header-offer {
  display: none;
}
.list-button{
    display: none;
}

@media (min-width: 768px){
  .header{
    height:60px;
    padding:10px 40px;
  }
}

@media(min-width:1024px){
  .header{
      height: 60px;
      padding: 5px 10% 8px 10%;
  }
  .logo-img{
      height: 50px;
  }

  .logo {
    width: 20%;
  }

  .header-offer {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
