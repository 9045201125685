.container-residentials{
    background-color:#f5f5f5;
    margin-top: 40px;
    font-family: Roboto;
}
.residential-types{
    padding:0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search-question{
    font-size:22px;
    color: #222222;
    font-weight:400;
    text-align: center;
    margin: 20px 20px 5%;
}
.estate{
    width:45px;
    height:45px;
}
.residential-info{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:7%;
    font-family: 'Roboto';
    cursor: pointer;
}
.residential-description{
    text-align: center;
    margin-top:0;
    line-height: 26px;
    color: #535353;
    font-size: 15px;
    text-indent:3px;
}
.residential-name{
    margin:5%;
    color: #95c41f;
    font-weight: bold;
    font-size:16px;
    font-family: Roboto;
}

.estate-type-contact-button {
  text-align: center;
}
.about-button{
    width:auto;
    height:44px;
}
@media(min-width:768px){
.search-question{
    font-size:25px;
}
.estate{
    width:75px;
    height:75px;
    }
.residential-description{
    font-size: 18px;
    vertical-align: inherit;
    font-family: Roboto;
}
.residential-name{
    font-size:20px;
}
.residential-info{
    margin:20px;
}
}
@media(min-width:1024px){
.container-residentials{
    height:auto;
    padding-bottom: 30px;
}

.residential-types{
    padding:0 10%;
}
.estate{
    width: 70px;
    height: 70px;
    padding-bottom:6%;
}
.residential-types{
    flex-direction: row;
}
.residential-description{
    text-indent: 5px;
}
.search-question{
    margin:70px auto 10px auto;
    font-size: 30px;
}
.residential-info{
    margin: 40px;
}
.about-button{
    width:500px!important;
}
.about-button:active{
    outline: none;
}

}
