
.attachment-form {
    &__upload-files {
      padding: 20px 5px;
      border:1px dashed #e6e6ff;
      width: 80%;
      cursor: pointer;
      width:95%;
    }
    &__upload-content:focus{
      outline: none;
    }


    &__existing-files {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;
      
    }

    &__file-list {
      list-style: none;
      padding: 0;
      width: 80%;
      padding-right: 10px;
      overflow-wrap: break-word;

      li {
        color: rgba(0, 0, 0, 0.4);
        padding-left: 10px;
      }
    }

    &__remove-files {
      button {
        cursor: pointer;
        background-color: transparent;
        border:1px solid #e6e6ff;
        padding:0 5px;
      }
      button:hover{
        border:1px solid #b3b3ff;
      }
      h4{
        margin:0;
      }
    }
    &__document-delete{
        color:black;
        font-size: 12px;
    }
    @media(min-width:1024px){
      &__upload-files{
        width:98%;
      }
      &__existing-files {
        flex-direction: row;
      }
      &__remove-files {
        button {
          cursor: pointer;
          height:30px;
        }

      }
    }
  }

  .drop-instruction{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding:0 20px;
      position: relative;
      align-items: center;

  }
  .upload-indication{
      font-family: inherit;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.12px;
      color: rgba(0, 0, 0, 0.4);
      margin:0;
  }
  .drop-icons-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  .upload-icon{
      width:16px;
      margin-left:10px;
  }
  .close-icon{
    position: relative;
    right:0;
    width:14px;
  }
