form{
    display: flex;
    flex-direction: column;
    padding:3%;
}

  .label{
    margin:10px 0 0;
    font-weight: bolder;
  }

  .contact-form{
    width:100%;
        input[type=text], input[type=email], input[type=number], textarea, .contact-text-area{
           width: 100%;
           margin:5px 0;
           border: 1px solid #ced4da;
           border-radius: .25rem;
           background-color: #f8f9fa;
           font-family: inherit;
           color:#495057;
         }
         input[type=text], input[type=email], input[type=number]{
           height:40px;
         }
         input::placeholder, textarea::placeholder{
           color:#495057;
           font-size: 14px;
           font-weight: 400;
           line-height: 1.5;
           padding-left: 5px;
         }
  }
  .send-message{
    width: 95%;
    height: 40px;
    padding: 10px 15px;
    font-size: 14px;
    margin: 20px auto;
  }
  .send-message:hover{
    background-color:#A5E11B;
    color:#FFFAFA;
  }

  @media(min-width:1024px){

  }
