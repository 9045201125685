.properties-container {
  background-color:#f5f5f5;
  padding: 0;
  font-family: Roboto;

  h2 {
    padding:20px 0 0;
    text-align: center;
    color: #222222;
    font-size: 28px;
    font-weight: 400;
  }
}
a{
    text-decoration: none;
}
.properties-link{
    text-decoration: none;
}
.properties-wrapper{
    padding:0 5%;

    &__no-search-result {
      font-size: 18px;
      color: #95c41f;
      text-align: center;
      margin-bottom: 40px;
    }
}
.individual-property{
    display: block;
    width:100%;
    background-color:white;
    margin:20px 0;
    text-decoration: none;
    z-index: 1;
}

.property-wrapper {
  cursor: pointer;
}

.img-property {
    position: relative;
    display: block;
}

.property-main-image-link {
  position: relative;
}

.property-main-image-wrapper {
  opacity: 1;
  background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),color-stop(0,rgba(0,0,0,0)),color-stop(50%,rgba(0,0,0,0)),to(rgba(0,0,0,.75)));
  background-image: -o-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,0) 0,rgba(0,0,0,0) 40%,rgba(0,0,0,.75) 100%);
  background-image: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0) 0,rgba(0,0,0,0) 40%,rgba(0,0,0,.75) 100%);
  display: flex;
  position: absolute;
  height: 240px;
  width: 100%;
  top: 0;
  z-index: 2;

}

.property-main-image-wrapper:hover {
  opacity: 0;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.property-main-image {
  height: 240px;
  object-fit: cover;
  z-index: -1;
  width: 100%;
}


.promoted{
    background-color: #90c923;
    color:#ffff;
    padding:3px;
    font-size: 12px;
    width:80px;
    text-align: center;
    position: absolute;
    top:10px;
    left:10px;
    font-family: Roboto;
    font-weight: 500;
    border-radius: 10px;
}

.exclusivitate {
  background-color: red;
  color:#ffff;
  padding:3px;
  font-size: 12px;
  width:80px;
  text-align: center;
  position: absolute;
  top:10px;
  left:10px;
  font-family: Roboto;
  font-weight: 500;
  border-radius: 10px;
  opacity: 0.8;
}

.tranzaction-type{
    width:70px;
    position: absolute;
    top: 5%;
    right: 4%;
    padding: 3px 5px;
    background-color: #1a1a1a;
    border-radius: 10px;
}
.tranzaction{
    font-size: 12px;
    font-weight: 800;
    color: #ffff;
    font-family: Roboto;
    margin:0;
    text-align: center;
    padding-top:3%;
}
.actions-box{
    position: absolute;
    bottom:10px;
    right:0;
    z-index:7;
}
.property-heart{
    width:18px;
    background-color: #727376;
    padding:5px;
    margin-right: 5px;
    border-radius: 5px;
    z-index: 7;

    &.highlight {
      background-color: #85c341;
    }

    &--single {
      background-color: white;
    }
}
.hightlight{
    background-color: #85c341;
}
.property-heart:hover, .property-heart:active{
    background-color: #85c341;
    opacity:1;
    cursor: pointer;
}
.price-share{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom:10px;
    z-index: 2;
}
.price{
    margin:0 0 0 20px;
    color:#ffff;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 550;
    width: 100%;
    text-align: left;
}
.property-extra-info{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    padding:20px;
    border-top: 1px solid #dce0e0;
    .property-extra-box{
        display: flex;
        flex-direction: row;
        img{
            width:16px;
            height:16px;
            margin-right: 5px;
        }
        h4{
            margin:0;
            font-weight: 400;
            font-family: Roboto;
            font-size: 14px;
            color: #636363;
            text-align: left;
        }
    }
}
.time-posted{
    background-color: rgba(239, 239, 239, 0.85);
    width:100px;
    height:25px;
    position: absolute;
    bottom: 4%;
    right: 4%;
    padding:6px 12px;
}
.time-track{
    font-family: Roboto;
    font-size: 14px;
    color:	#778899;
    margin:0;
    padding-top:5%;
    text-align: center;
}
.property-title{
    color: #222222;
    margin-left: 20px;
    margin-top: 10px;
    position: relative;
    font-weight: 500;
    padding-right: 20px;
    font-size: 16px;
    text-transform: capitalize;
    font-family: Roboto;
    text-align: left;
    min-height: 35px;
}
.property-adress{
    display: flex;
    flex-direction: row;
    padding-left: 20px;
        p{
            margin:0;
            font-weight: 550;
            font-family: Roboto;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #636363;
            font-weight: lighter;
            margin-bottom:10px;
            text-align: left;
        }
}
.property-info{
display: flex;
flex-direction: row;
}
.detail-icon{
    width:14px;
    height:14px;
    position: relative;
    top:3px;
    margin-right: 5px;
}
.detail-item{
    font-size: 14px;
    color:#222222;
}
.property-details{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    width:50%;

}
.property-type{
    color: #222222;
    font-size: 12px;
    padding:0 0 20px 20px;
    margin:0;
}

.icon-div{
    width: 5%;
    border-left:0.5px solid 	rgb(217, 217, 217);
    padding:5%;
    z-index: 2;
}
.call-agent{
    height: 30px;
    width: 90px;
    font-size:11px;
    background: #95c41f;
    border: 2px solid #95c41f;
    color:#ffff;
    cursor:pointer;
    letter-spacing: 0.5px;
    font-weight: 600;
    border-radius: 2px;
    height:29px;
}
.properties-unavailable{
    background-color: red;
    width:130px;
    height:auto;
    position: relative;
    bottom: 30px;
    left: 30px;
    border-radius: 5px;
}
.properties-unavailable-writing{
    font-size: 10px;
    color:#ffff;
    margin:0;
    padding:5px;
    text-align: center;
    letter-spacing: 0.52px;
    font-family: Roboto;
}
.properties-new-box{
    background-color: red;
    width: 50px;
    border-radius: 5px;
    position: absolute;
    top: 8%;
    height:22px;
    right: 5%;
}
.new-alert{
    color:#ffff;
    font-size: 14px;
    text-align: center;
    margin:0;
    margin-top: 3px;
}
.only-tablet{
    display: none;
}
.properties__see-more-properties{
    color: #fff;
        background-color: #90c923;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        border:none;
        border-radius: 5px;
        width:180px;
        height:35px;
        margin:0 auto 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        top:10px;
}
.properties__see-more-properties:hover, .property__see-details:hover{
    background-color: #8bc322;
}
.properties__see-more-properties:focus, .property__see-details:focus{
    outline:none;
}
a:hover{
    text-decoration: none;
}
.property-last-updates{
    font-size: 12px;
    position: absolute;
    top:10px;
    left:10px;
    background-color: grey;
    color:#ffff;
    padding: 3px;
    border-radius: 3px;
    opacity: 0.5;
}
@media(min-width:768px){
    .properties-container {

      h2 {
          font-size:25px;
      }
    }
    .properties-wrapper{
       display:flex;
       flex-wrap:wrap;
       justify-content: space-around;
    }
    .individual-property{
      width: 48%;
      margin:10px 5px 20px;
    }
    .icon-div{
        padding:4%;
    }
    .call-agent{
        display: none;
    }
    .property__details-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .property__see-details{
        color: #fff;
        background-color: #90c923;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        border:none;
        border-radius: 5px;
        width: 25%;
        height:35px;
        margin-right: 20px;
        align-items: center;
        display: flex;
        justify-content: center;

    }
    .property__see-details:hover, .property__see-details:active{
        background-color: #8bc322;
    }

}
@media(min-width:1024px){
    .properties-container {
        margin-top: 20px;
      h2 {
          font-size:30px;
          margin-bottom: 30px;
      }
    }
    .properties-wrapper{
        padding: 0 9%;

        &__no-search-result {
          font-size: 26px;
        }
    }
    .individual-property{
        width:31%;
        margin:10px 5px 20px;
    }
    .property-title{
     position: relative;
     top:12px;
    }
    .property-adress{
        p{
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
        }
    }
    .property-info{
        padding:0 4%;
    }
    .properties-new-box{
        width: 55px;
        height: 24px;
    }
    .new-alert{
        text-align: center;
    }
    .individual-grid-property{
        width:100%;
        margin:20px 0;
    }
    .img-grid-property{
        width:25%;

    }
    .properties-grid-wrapper{
        padding:0 10%;
    }
    .property-grid-wrapper{
        display: flex;
        flex-direction: row;
    }
    .grid-details{
        width:80%;
        padding:0 40px 12px 10px;
    }
    .property-grid-details-info{
        width:25%;
    }
    .property-grid-detail{
        width: 25%;
    }
    .property-grid-type{
        position: relative;
        top:16px;
    }
    .property-grid-extra-info{
        width:25%;
        border:none;
    }
    .grid-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button{
            color: #fff;
            background-color: #90c923;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
            border:none;
            border-radius: 5px;
            width:120px;
            height:35px;
            align-items: center;
            display: flex;
            justify-content: center;
        }
        button:focus{
            outline:none;
        }
    }
    .grid-details-top{
        padding:20px 0 0 20px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .property-grid-title{
        top:0;
        margin-bottom: 0;
    }
    .price-share-grid{
        position: initial;

        width:initial;
        h3{
            color:#222222;
            margin:0;
        }
    }
    .tranzaction-grid-type{
        opacity: 1;
       position: initial;
    }
    .container-relative{
        margin-top: 40px;
    }
    .property-details{
        width:60%;
    }
    .property__see-details{
        position: relative;
        bottom:10px;
        width:80px;
    }
}
