.single-property-wrapper{
    background-color: #f8f9fa;
    font-family: Roboto;
}
.sp-first-part, .sp-second-part{
    padding:20px;
}
.sp-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h4{
        font-size: 12px;
        font-weight: 300;
    }
    span{
        color:#007bff;
    }
}
.sp-top-box{
    margin-top: 20px;
    img{
        width:16px;
        padding:5px;
        border:1px solid grey;
        border-radius: 5px;
    }
    img:hover{
        background-color: #90c923;
        border:1px solid #90c923;
    }
}

.sp-title{
    color:#222222;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.2;
}
.property-price{
    font-weight: 500;
}
.sp-type{
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    line-height: 11px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 5px;
    margin-top: 10px;
    color: #fff;
    background-color: rgba(0,0,0,.65);
    width:100px;
}
.sp-location{
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    h5{
        font-size: 16px;
        color:#636363;
        margin:0;
        font-weight: 400;
    }
    img{
        width:20px;
        margin-right: 5px;
    }
}
.property-img{
    width:100%;
    height:auto;
    max-height: 400px;
    object-fit: contain;
    cursor: pointer;
}

.nav-slider-property-img {
  height: 100px;
  object-fit: contain;
}

.sp-subtitle-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:40px 0 0;
    h4{
        font-size: 14px;
        line-height: 25px;
        color:#222222;
        font-weight: 400;
    }
}
.sp-subtitle{
    font-size: 18px;
    color:#222222;
    font-weight: 400;
}
.sp-summary{
    padding:0 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #dce0e0;
    margin-bottom: 20px;
}
.sp-detail{
    display: flex;
    flex-direction: column;
    width:45%;
    margin:10px 0;
    h3{
        color: #222222;
        font-size: 16px;
        margin:0;
        font-weight: 400;
    }
    h4{
        font-size: 14px;
        margin:0;
        color:#5c6872;
        font-weight: 400;
    }
    div{
        display: flex;
        flex-direction: row;
        img{
            width:16px;
            height:16px;
            margin-right: 5px;
        }

    }
}
.sp-description-box{
    border-bottom: 1px solid #dce0e0;
    padding:0 0 20px;
    margin-bottom: 20px;
}
.sp-second-box{
    padding:20px 0;
}
.subtitle-top{
    display: flex;
    flex-direction: row;
    margin:10px 0;
    img{
        width:16px;
        margin-right: 5px;
    }
    h5{
        margin:0;
        color:#636363;
        font-size: 14px;
    }
}
.sp-lower-subtitle{
    margin:0;
    width:100%;
}
.sp-info-wrapper{
    padding:20px;
    background-color: #ffff;
    margin-bottom: 20px;
    border: 1px solid #dce0e0;
}
.sp-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid  #e1f4be;
    margin:20px 0;
    h3, h4{
        margin:0 0 5px;
        font-size: 14px;
        color: #222222;
    }
    h3{
        font-weight: bolder;
    }
    h4{
        font-weight: lighter;
    }
}
.sp-specifications{
    padding:20px 0;
    margin-bottom: 20px;
    border-top: 1px solid #dce0e0;
    border-bottom: 1px solid #dce0e0;
}
.list-title{
    color:#222222;
    font-weight: bolder;
    font-size: 16px;
    margin:5px 0 10px;
}
.sp-list{
    padding-left: 35px;
    list-style-type: none;
    list-style-image: url(/images/arrow-green.png);
    li{
        color: #222222;
        font-size: 16px;
        padding:10px 0;
    }
    span{
        font-weight: bolder;
    }
}
.sp-form-container{
    background-color: #fff;
    padding:20px;
    border-radius: 5px;
}
.sp-form-top{
    display: flex;
    flex-direction: row;
}
.sp-form{
    input[type=text], input[type=email], input[type=number], textarea, .contact-text-area{
        width: 100%;
        margin:5px 0;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        background-color: #f8f9fa;
        font-family: inherit;
        color:#495057;
      }
      input[type=text], input[type=email], input[type=number]{
        height:40px;
      }
      input::placeholder, textarea::placeholder{
        color:#495057;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        padding-left: 5px;
      }
}
.sp-user-icon{
    width:70px;
    height:70px;
    border-radius: 5px;
    margin-right: 20px;
}
.sp-user-box{
    div{
        display: flex;
        flex-direction: row;
        img{
            width:20px;
            margin-right: 5px;
            height:20px;
        }
        h4{
            margin:0;
            font-size: 14px;
            color: #222222;
            font-weight: 300;
            padding-top: 3px;
        }
    }
    h4{
        color: #007bff;
        cursor: pointer;
        font-weight: 600;
        margin:10px 0 0;
        font-size: 14px;
    }
    h5{
        font-size: 14px;
        font-family: Roboto;
        font-weight: 300;
        padding-top: 5px;
        text-align: center;
    }
}
.sp-form{
    padding:20px 0;
}
.sp-textarea{
    height:auto;
}
.sp-field{
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
}
.sp-field::placeholder{
    font-weight: 400;
    line-height: 1.5;
    color:#999999;
}
.sp-consent{
    color: #222222;
    cursor: pointer;
    font-weight: 400;
}
.sp-button-box{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    button{
        height:35px;
        border-radius: 5px;
        font-weight: bolder;
        margin-bottom: 10px;
        font-size: 14px;
        cursor: pointer;
    }
}
.sp-message-button{
    color: #fff;
    background-color: #28a745;
    border:none;
    width:45%;
}
.sp-call-button{
    color:#28a745;
    border:1px solid #28a745;
    width:90%;
    background-color: transparent;
}
.sp-call{
    width:45%;
}

.sp-wa-call {
  width: 95%;
}

.sp-whatsapp-button{
    color:#28a745;
    border:1px solid #28a745;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:100%;
    img{
        width:18px;
        margin-right: 10px;
    }
    h5{
        margin:0;
        font-size: 14px;
    }
}

.nav-slider-wrapper {
  background-color: #ccccce;
  padding: 10px 0;
}

.nav-slider {
  width: 80%;
  margin: 5px auto;
  box-sizing: content-box;

  .slick-slide {
    margin: 0 5px;
    border: 3px solid transparent;
  }

  .slick-current {
    border: 3px solid white;
  }

  .slick-list {
    margin: 0 -5px;
  }
}
.sp-call{

}

@media(min-width:768px){
    .sp-container{
        background-color: #f5f5f5;
    }
    .sp-first-part, .sp-second-part{
        padding:20px 40px;
    }
    .sp-property-images{
        padding:0 40px;
    }
    .sp-subtitle-box{
        border-bottom: 1px solid #dce0e0;
    }
    .sp-summary{
        border:none;

    }
    .sp-summary-container{
        background-color: #fff;
        padding:0 20px;
        margin-bottom: 20px;
    }
    .sp-detail{
        width:20%;
        padding-top: 16px;
        margin-bottom: 0;
        h3{
            padding-bottom: 5px;
        }
    }
    .sp-description-box{
        background-color: #fff;
        border:none;
        padding:20px;
    }
    .sp-subtitle-border{
        border-bottom: 1px solid #dce0e0;
        padding-bottom: 20px;
    }
    .sp-details-wrapper{
        background-color: #fff;
        border:none;
        padding:20px;
    }
    .sp-info-wrapper{
        background-color: #ffff;
        border-color:  #e1f4be;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .sp-info-first, .sp-info-second{
        width:47%;
    }
    .sp-specifications{
        border:none;
        margin-top: 20px;
        background-color: #fff;
        padding:10px 20px;
    }
    .sp-specifications-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div{
            width:30%;
            li{
                margin:10px 0;
            }
        }
    }
    .sp-form-container{
        margin:0 40px 40px;
    }
}
@media(min-width:1024px){
    .sp-title{
        font-size:30px;
        font-weight: 400;
    }
    .sp-first-part{
        padding:20px 10%;
    }
    .sp-second-part{
        padding:20px 5% 20px 0;
    }
    .sp-left-side{
        width:70%;
    }
    .sp-property-images{
       padding: 0 5% 0 0;
    }
    .sp-desktop-container{
       display: flex;
       flex-direction: row;
       padding: 0 10%;
    }
    .sp-form-wrapper{
        position: relative;
        width: 30%;
    }

    .sp-form-container {
      margin: 0;
    }
    .sp-top-box img{
        margin:0 2px;
        cursor: pointer;
    }
    .sp-list li{
        font-size: 15px;
        font-family: Roboto;
        font-weight: 300;
    }
    .sp-description-box{
        margin:30px 0;
    }
    .sp-specifications{
        margin-top: 30px;
        padding-top: 40px;
        li{
            margin:16px 0!important;
        }
    }
    .sp-type{
        margin-bottom: 20px;
    }
    .sp-title-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .property-price{
        font-size: 20px;
        position: relative;
        top:25px;
    }

    .sp-message-button {
      width: 44%;
    }
    .sp-call-button{
        width:90%;
    }

    .sp-message-button:hover{
        background-color: #343a40;
    }
    .sp-call-button:hover, .sp-whatsapp-button:hover{
        color: #343a40;
    }

    .nav-slider-wrapper {
      padding: 10px 0;
      display: flex;
      justify-content: flex-start;
      position: relative;
      margin-top: -100px;
      background-color: rgba(255,255,255,0.5);
    }

    .nav-slider {
      width: 80%;

      .nav-slider-property-img {
        cursor: pointer;
      }

    }
    .sp-subtitle{
        padding-bottom: 30px;
        margin-bottom: 15px;
    }

    .property-img {
      max-height: 600px;
    }
}

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
    max-height: 70px;
    vertical-align: middle;
    pointer-events: all;
}

.carousel .slide img {
    width: 100%;
    min-width: 100%;
    background-color: #cdcdcd;
    width: auto;
    pointer-events: all;
}

.carousel .control-arrow {
  background: black;
  margin-top: -20px;
}
