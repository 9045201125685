.banner-default{
    height: 300px;
    position: relative;
    

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}
.banner-image-text {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.title-header{
    margin: 0 0 15px;
    text-shadow: 1px 1px 3px #000;
    font-weight:550;
    text-transform: uppercase;
    color: #dadada;
    text-align: center;
    padding-top:70px;
    font-size:34px;
    letter-spacing: 0.5px;
}
.subtitle-header{
  text-shadow: 1px 1px 3px #000;
    font-size: 16px;
    color: #dadada;
    font-family: inherit;
    text-align: center;
    font-weight: 300;
    letter-spacing: 1px;
}
@media(min-width:1024px){
    .banner-default{
      height:500px;
    }
    .title-header{
      font-size:36px;
      font-weight: 700;
    }
    .subtitle-header{
      font-size: 18px;
    }
}
