.banner-property{
    background: rgba(0, 0, 0, 0.04) url("/images/bannerImages/vanzari-banner.jpg");
    background-size: cover;
    height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.3);
    display:block;
}

.title-property{
    margin: 0 0 15px;
    font-weight:550;
    text-transform: uppercase;
    color:#ffa500;
    text-align: center;
    padding-top:40px;
    font-size:17px;
    letter-spacing: 0.5px;
}
.subtitle-property{
    font-size: 14px;
    color:#ffa500;
    font-family: inherit;
    text-align: center;
    font-weight: 300;
    letter-spacing: 1px;
}

.pagination {
  background-color: #f5f5f5;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 10px auto;
  padding-bottom: 20px;

  li {
    cursor: pointer;
    color: #95c41f;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 0;
    margin: 0 5px;
    font-weight: bolder;

    a {
      padding: 5px 10px;
      color: #95c41f;
    }

    a:focus {
      outline: none;
    }

    &:hover {
      background-color: #95c41f;
      color: white;

      a {
        color: white;
      }
    }
  }

  .active {
    background-color: #95c41f;
    color: white;

    a {
      color: white;
    }
  }

  .previous, .next {
    border: 1px solid #95c41f;
  }
}
.properties-only-desktop{
    display: none;
}
.properties-grid-box{
    padding:0 20px;
    h2{
        margin:20px 0 0;
        font-family: Roboto;
    }
}
.sort-box{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    h3{
        color:#222222;
        font-size: 16px;
        margin:14px 10px 0 0;
        font-weight: lighter;
    }
    .MuiNativeSelect-root{
        padding:0;
    }
    .MuiInput-input{
        text-indent: 5px;
        color: #999;
        font-weight: bolder;
    }
}
    .properties-number{
        color:#222222;
        font-size: 14px;
        font-weight: 300;
        margin:5px 0 0;
        position: relative;
        top:20px;
}
@media(min-width:768px){
    .banner-property{
        height:250px;
    }
    .title-property{
        padding-top:50px;
        font-size:20px;
    }
    .subtitle-property{
        font-size:16px;
    }
    .properties-filter-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .properties-grid-box{
        padding:0 40px;
    }
    .properties-number{
        position: relative;
        left:5px;
        padding-bottom: 10px;
    }
}
@media(min-width:1024px){
    .only-mobile{
        display: none;
    }
    .only-desktop{
        display: flex;
    }
    .banner-property{
    height:300px;
    padding:0 15%;
    }
    .title-property{
    font-size:23px;
    font-weight: 700;
    padding-top:6%;
    }
    .subtitle-property{
    font-size: 13px;
    }
    .properties-only-desktop{
        display: flex;
        flex-direction: row;
        position: relative;
        top:40px;
        img{
            width:20px;
            height:20px;
            margin-right: 15px;
            cursor: pointer;
        }
        .list-grid{
            width:22px;
            height:22px;
            margin-right: 0;
        }
    }
    .properties-grid-box{
        padding:20px 10% 0;
    }
    .grid-desktop{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }
    .sort-box{
       justify-content: flex-end;
       width:100%;
       .MuiNativeSelect-root{
        padding:0;
        background-color: transparent!important;
        border:none!important;
        position: relative;
        bottom:10px;
    }
    .MuiInput-input{
        text-indent: 5px;
        color: #999;
        font-weight: bolder;
    }
    }
    .properties-number{
        left:0;
    }
    .properties-filter-wrapper{

        justify-content: space-between;
        width:100%;
    }
}
