.filter{
    &__top{
        background-color: #505152;
        padding:5px 0;
    }
    &__search-button{
        display: flex;
        flex-direction: row;
        justify-content: left;
        width:90%;
        background-color: #fff;
        margin:5px 10px;
        height:40px;
        align-items: center;
        border:none;
        border-radius: 5px;
        img{
            width:16px;
            margin-right: 5px;
        }
        h5{
            margin:0;
            font-size: 14px;
            color: #222222;
            font-weight: 500;
        }
    }
    &__wrapper {
        width: 100%;
        height:100%;
        z-index: 20;
        clear: both;
        overflow: hidden;
        top:0;
        left: 0;

        margin-top: 0;
        background-color: #505152;
        position: fixed;
        transform: translateY(-100%);
        transition: transform .3s;
        overflow-x: auto;

        &.active {
          transform: translateX(0);
        }
      }
    &__top-box{
        padding:20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        h2{
            margin:0;
            color:#fff;
            font-size: 18px;
        }
        img{
            width:18px;
            height:18px;
        }
    }

    &__search-box {
      margin: 10px 0;
    }

    &__search-input{
        height: 30px;
        border-radius: 5px;
        border:none;
        width: 99%;
    }
    &__search-input::placeholder{
        background-image: url('/images/search.png');
        background-size:contain;
        background-repeat: no-repeat;
        text-indent: 20px;

    }
    &__dropdown-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width:100%;
    }
    &__dropdown{
        width: 47%;
        margin:10px 0;
        box-sizing: border-box;

        .dropdown, button {
          box-sizing: border-box;
        }

        .dropdown-item {
          width: auto!important;
        }

        .bs-searchbox {
          input {
            box-sizing: border-box;
          }
        }
    }

    &__general-input{
        width:46%!important;
        margin-top: 10px!important;
    }
    &__general-input:focus{
        outline: none;
    }
    &__submit-button{
        background-color: #90c923;
        border:none;
        color:#fff;
        font-weight: bolder;
        font-size: 14px;
        height:35px;
        margin-top: 15px;
    }
    &__only-desktop{
        display: none;
    }

    &__oferta-input {
      box-sizing: border-box!important;
    }

@media(min-width:768px){
    &__top-box{
        padding-top:40px;
    }
}
@media(min-width:1024px){
    &__search-input{
        position: relative;
        top:10px;
        height: 2.2rem;
    }
    &__only-mobile{
        display: none;
    }
    &__only-desktop{
        display: block;
        background-color: #505152;
        padding:0 8%;
    }
    &__form{
        padding:5px 3%;
    }
    &__form-top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__search-box{
        width:35%;
        margin: 0;
    }
    &__dropdown{
        width:18%;
    }
    &__options{
        display: flex;
        flex-direction: row;
        position: relative;
        top:15px;
        cursor: pointer;
        img{
            width:20px;
            height:20px;
            margin-right: 5px;
        }
        h4{
            margin:0;
            color:#fff;
            font-size: 16px;
        }
    }
    &__submit-button{
        width:120px;
        border-radius: 5px;
        cursor: pointer;
        height:40px;
        position: relative;
        bottom:10px;
    }
    &__submit-button:focus{
        outline:none;
    }
    &__form-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    &__bottom-input{
        width:24%!important;
    }
}
}
