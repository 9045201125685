.agent{
    background-color: #f5f5f5;
    width:100%;
    &__wrapper{
        padding:20px;
    }
    &__box{
        background-color:#ffff;
        padding:20px 40px;
        margin:0 0 20px;
        border-radius: 3px;
    }
    &__images{
        position: relative;
    }
    &__main-img{
        width:100%;
    }
    &__logo{
        width:140px;
        height:40px;
        background-color: #fff;
        padding:5px;
        position: absolute;
        left:0;
        bottom:5px;
    }
    &__agent-name{
        line-height: 1.2;
        color: #222222;
        font-weight: 400;
        font-size: 32px;
        margin:0;
    }
    &__function{
        font-size: 14px;
        margin:5px 0 10px;
        border-bottom: 1px solid #dce0e0;
        padding-bottom: 5px;
    }
    &__info{
        display: flex;
        flex-direction: row;
        margin:10px 0;
        img{
            width:16px;
            height:16px;
            position: relative;
            top:5px;
        }
        h4{
            margin:0 5px;
            font-size: 14px;
            font-weight: 300;
            line-height: 25px;
        }
        .bold{
            font-weight: bolder;
        }
    }
    &__buttons-box{
        display: flex;
        flex-direction: column;
        button{
            margin:5px 0;
            height:35px;
            font-size: 14px;
            font-weight: bolder;
            cursor: pointer;
            border-radius: 3px;
        }
        button:focus{
            outline: none;
        }
    }
    &__general-button{
        background-color: #90c923;
        border:none;
        color:#ffff;
     
    }
    &__general-button:hover{
        background-color: #8bc322;
    }
    &__call-button{
        color: #90c923;
        background-color: transparent;
        border:1px solid #90c923;
    }
    &__call-button:hover, &__whatsapp-button:hover{
        color:#6c981b;
        border-color: #6c981b;
    }
    &__whatsapp-button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #90c923;
        align-items: center;
        background-color: transparent;
        border:1px solid #90c923;
        img{
            width:16px;
            height:16px;
            margin-right: 5px;
        }
        h5{
            margin:0;
            font-size: 12px;
        }
    }
    &__self-description{
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        color:#222222;
    }
@media (min-width:768px){
    &__wrapper{
        padding:20px 40px;
    }
    &__box{
        display: flex;
        flex-direction: row;
    }
    &__images{
        width:30%;
    }
    &__logo{
        bottom:29%;
    }
    &__right-side{
        padding-left: 30px;
    }
    &__buttons-box{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        button{
            width:48%;
        }
    }
    &__description-box{
        display: block;
    }
}
@media (min-width:1024px){
    &__box{
        margin:0 8% 20px;
    }
    &__images{
        width:25%;
    }
    &__main-img{
        height:350px;
        object-fit: cover;
    }
    &__right-side{
        width:70%;
    }
    &__logo{
        bottom:5px;
    }
    &__function{
        padding-bottom: 10px;
        margin-bottom: 15px;
    }
    &__buttons-box{
        flex-direction: row;
        flex-wrap:no-wrap;
        justify-content: space-between;
        width:70%;
        button{
            width:24%;
        }
    }
}
}
