.modal{
    position: relative;
    .MuiFormControl-root{
        border: 1px solid #ced4da;
        border-radius: .25rem;
        background-color: #f8f9fa;
        padding:5px;   
        width:97%;
     }
    .MuiInputBase-root{
        font-weight: 300;
        font-size: 14px;
        color:#222222;
    }
    &__close-icon{
        width:16px;
        height: 16px;
        position: absolute;
        top:10px;
        right:10px;
    }
    &__top{
        display: flex;
        flex-direction: row;
        padding:10px 10px 20px;
    }
    &__agent-photo{
        width:60px;
        height:80px;
        object-fit: contain;
        border-radius: 5px;
    }
    &__top-info{
        display: flex;
        flex-direction: row;
        margin:15px 0 0 10px;
        img{
            width:18px;
            height:18px;
            margin:5px;
        }
        h4{
            margin:0;
            font-weight: 300;
            line-height: 25px;
            font-size: 16px;
            color:#222222;
        }
    }
    &__submit-button{
        background-color: #28a745;
        width:100%;
        margin:20px 0 0;
        font-weight: bolder;
        border:none;
        border-radius: 5px;
        color:#ffff;
        height:35px;
        cursor: pointer;
    }
    &__data-consent{
        font-weight: lighter;
        margin:0;
        top:0;
    }
    &__dropdown{
        margin:5px 0;
    }
    &__feedback-agent-photo{
        position: relative;
        top:20px;
    }
    &__feedback-top{
      margin-top: 10px;
    }
    &__feedback-close{
        top:0;
    }
    @media(min-width:768px){
        &__top{
            padding-left: 20px;
        }
    }
    @media(min-width:1024px){
        &__top{
            padding-left: 40px;
        }
    }
}