.contact-container{
    background-color:#f5f5f5;
    font-family: Roboto;
}
.contact-title-color{
    color:#fff;
}
.contact-box{
    padding: 20px;
    background-color:#ffff;
    border-radius: 25px;
    position: relative;
    bottom:80px;
}

.contact-description{
    font-size:16px;
    font-weight: 300;
    line-height: 1.4;
    color: #222222;
    text-align:left;
    padding:0 5%;
  
}
   .general-p{
        font-size: 16px!important;
        letter-spacing: 0.52px;
        line-height: 20px;
        margin: 0 0 30px;
        box-sizing:border-box;
        color: #535353;
        text-indent: 5px;
    }

.contact_location {
  .google-location {
    width: 100vw;
    height: 300px;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }
}
.contact-link{
    text-decoration: none;
    color: #535353;
}
.contact-box-details{
   position: relative;
   bottom:80px;
    padding:20px;
    display: flex;
    flex-direction:column;
}
.detail-contact{
    font-weight: 500;
    font-size: 16px;
    color: #374051;
    font-family: Roboto;
    margin-top: 10px;

}
.contact-box-small{
    display: flex;
    flex-direction: row;
}
.contact-svg{
    color: #95c41f;
    padding:0 2%;
}

.contact-info-container{
    background-color: #fff;
}
.detail-contact-indication{
    font-size: 16px;
    font-family: Roboto;
}
@media(min-width:768px){
    .contact-box-details{
        position: relative;
        bottom:80px;
         padding:20px;
         display: flex;
         flex-direction:column;
         background-color: #fff;
         border-radius: 25px;
         margin-left: 40px;
         height:300px;
     }
     .contact-wrapper{
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         padding:20px;
     }
     .contact-info-container{
        background-color: #f8f9fa;
    }
     .contact-box{
        width:70%;
      }
    .contact_location {
      .google-location {
        height: 250px;
      }
    }
}
@media(min-width:1024px){
    .contact-description{
        padding:20px 0 0 20px;
        text-indent: 0;
    }
    .contact-info-container{
        padding:0 8%;
    }
    .contact-wrapper{
        width:85%;
    }
   .contact-box{
       width:60%;
       bottom:180px;
   }
   .contact-box-details{
       height:400px;
       bottom:180px;
   }
   .detail-contact{
       margin-top: 20px;
   }

}
