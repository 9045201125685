.banner-img{
    width:100%;
    vertical-align: middle;
    padding:1px;
    height: 300px;
    object-fit: cover;
}
.banner{
    height:250px;
    position: relative;
}
.container-introduction{
    position: absolute;
    height: 250px;
    width: 100%;
    top:0%;

    .introduction-text {
      padding: 10%;
    }
}
.motto{
    font-weight: 600;
    margin-top: 40px;
    font-size: 2.5rem;
    color:#ffff;
    text-align: center;
    line-height: 1.1;
    opacity:1;
    letter-spacing: 1px;
    font-weight: 400;
    text-shadow: 2px 2px 5px #000;
}
    
.short-intro{
    margin:0 0 10px 15px;
    color:#dadada;
    font-size:16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}
.introduction-buttons{
    display:flex;
    justify-content:center;
}
.general-button{
    font-size:11px;
    background: #95c41f;
    border: 2px solid #95c41f;
    color:#ffff;
    cursor:pointer;
    letter-spacing: 0.5px;
    font-weight: 600;
    border-radius: 2px;
    height:29px;
}

.learn-button{
    background-color: Transparent;
    background-repeat:no-repeat;
    overflow: hidden;
    outline:none;
    color:#95c41f;
    margin:4px;
}
.learn-button:hover{
    color:#FFFAFA;
}
.start-button{
    margin:4px;
}
.start-button:hover{
    background-color:#A5E11B;
    color:#FFFAFA;
}

@media (min-width:768px){
  .banner{
    height: 350px;
  }
  .banner-img{
    height:350px;
    padding: 0;
  }
  .container-introduction{
    height: 350px;
    padding:0;
    width:100%;
    top:2%;
}
  .motto{
    color:#ffff;
    letter-spacing: -0.7px;
    margin-top:15%;
}
.short-intro{
    margin:44px 15px 10px 15px;
    color:#dadada;
    font-size:18px;
    font-weight: 400;
    line-height: 20px;
}
.introduction-buttons{
    display:flex;
    justify-content:center;
}
.general-button{
    font-size:15px;
    height: 30px;
    width:180px;
}
.start-button{
    margin-right: 20px;
}
.learn-button{
    margin-right: 20px;
}
.introduction-buttons{
    margin-top:37px;
}

}
@media(min-width:1024px){
    .banner-img{
        vertical-align: middle;
        padding:0px;
        height: 550px;
    }
    .banner{
        height:400px;
    }
    .container-introduction{
        height: 400px;
        padding:0px;
        width:100%;
        top:1%;
        .introduction-text {
          padding: 0;
        }
    }
    .motto{
        margin-top: 10%;
        line-height: 40px;
    }
    .short-intro{
        line-height: 40px;
        font-size: 20px;
    }
    .general-button{
        height: 50px;
        width:190px;
    }
    .introduction-buttons{
        margin-top:37px;
    }
}
