.container-wrap{
    margin-right: auto;
    margin-left: auto;
    font-family: Roboto;
    font-weight: 400;
    position: relative;
    background-color:#f5f5f5;
}
.search-container{
    padding:20px;
    background-color: #f5f5f5;
}
.homepage__subtitle{
    font-size:22px;
    color: #222222;
    font-weight:400;
    font-family: inherit;
    text-align: center;
    margin:20px auto 10px;
    text-align: center;
}
.agents-section{
    margin:20px 0;
    padding:40px 20px 10px;
    background-color: #ffff;
}
.homepage-agent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:10px 0 40px;
}
.agent-img{
    width:150px;
    height:150px;
    border-radius: 50%;
    margin:0 auto;
    object-fit: cover;
}
.agent__name{
    color: #90c923;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    margin:10px 0 0;
    cursor: pointer;
}
.agent-function{
    font-size: 14px;
    text-align: center;
    margin:5px 0 0;
}
.agent-description{
    color: #636363;
    text-align: center;
    margin:10px 0 0;
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
}
.agent-see-profile{
    color: #90c923;
    font-weight: 500;
    text-align: center;
    font-size: 15px;
    margin:20px 0 0;
    cursor: pointer;
}
.agent__name:hover, .agent-see-profile:hover{
    color:black;
}
.homepage-bottom-wrapper{
    position: relative;
    bottom:120px;
}
@media(min-width:766px){
    .search-container{
        padding:20px 40px;
    }
    .homepage__subtitle{
        bottom:90px;
    }
    .agents-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }
    .homepage-agent{
        width:48%;
    }
}
@media(min-width:1024px){
    .agents-section{
        margin:0 10%;
        padding:0;
    }
    .agents-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:0 20%;
    }
    .homepage__subtitle{
        margin-bottom: 40px;
        font-size: 28px;
    }
    .agent-description{
        width:75%;
        margin:10px auto;
    }
    .homepage-agent{
        padding:40px 0;
        cursor: pointer;
    }
    .homepage-agent:hover{
        transform: scaleY(1.05);
        transform-origin: bottom;
        background-color: #ffff;
        box-shadow:0 0 10px #000000;
    }
    .homepage-bottom-wrapper{
        position: relative;
        bottom:100px;
    }
}
