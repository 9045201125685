.faq-area{
    padding:5%;
    background-color:#f5f5f5;
}
.list-type{
    display: flex;
    flex-direction: row;
}

@media(min-width:1024px){
    .faq-area{
        padding: 3% 10%;
    }
    .faq-titles{
        padding: 2% 8% 0;
    }
    .q-and-a{
        padding:1%;
    }
    .question{
        margin:1%;
    }
 
}
