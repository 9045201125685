.about-wrapper{
    padding:20px;
    background-color:#ffff;
    border-radius: 25px;
    position: relative;
    bottom:80px;
    font-family: Roboto;
}
.about-title-color{
    color:#ffff;
}
.about{
    background-color: #f8f9fa;
}

.general-p{
    font-size: 14px;
    letter-spacing: 0.52px;
    line-height: 20px;
    margin: 0 0 10px;
    box-sizing:border-box;
    color: #535353;
    text-indent: 5px;
}
.about-title{
    text-align: center;
    margin: 0 0 20px;
    font-weight: 300;
    color:#222222;
    font-size: 30px;
    font-family: Roboto;
}
.welcome-subtitle{
    margin: 0 0 15px;
    font-weight: 600;
    line-height: 25px;
    color: #37404d;
    font-family: Roboto;
    font-size: 18px;
}
.read-more{
    padding:5%;
    width:115px;
}
.read-more:hover{
    background-color:#A5E11B;
    color:#FFFAFA;
}
.agents-pic{
    height:280px;
}
.agent-presentation{
    display: none;
}
.agent-title{
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 25px;
    color: #37404d;
    font-family: inherit;
    text-align: center;
    margin:10% 0 0;
}
.agent-name{
    color: #343a40;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
    font-family: inherit;
    text-align: center;
    margin:3%;
}
.profession{
    text-align:center;
}
.social-media{
    display:flex;
    justify-content: center;
    height:40px;
}
.social-media-icon{
    width:20px;
    padding:0 2.5%;
    opacity:1;
    color:#95c41f;
}
.about-p{
    text-indent: 0;
    color:#212529;
}
.about-img{
    width:100%;
    height:200px;
    margin:10px 0;
    object-fit: cover;
}
.service-icon{
    position: relative;
    top:8px;
}
.services-list{
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
}
.service-item{
    font-family: 'Open Sans', sans-serif;
    padding:5px 0;
    font-size:14px;
    color:#212529;
}
.italic-p{
    font-style: italic;
}
@media(min-width:768px){
    .about-title{
        font-size:24px;
    }
    .welcome-subtitle{
        font-size:17px;
    }
    .about-pic{
        height:auto;
    }
    .about-wrapper{
        padding:40px;
    }
    .read-more{
        width:130px;
        height:40px;
        padding:2%;
        font-size:11px;
    }
    .agent-title{
        margin-top:5%;
        font-size: 28px;
    }
    .social-media-icon{
        width:25px;
    }
    .agent-name{
        padding:0 3%;
        font-size:20px;
    }
    .profession{
        font-size:18px;
    }
    .agents{
        padding-bottom: 3%;
    }
}
@media(min-width:1024px){
    .about-top{
        display: flex;
        flex-direction: row;
    }
    .about-introduction{
      width:60%;
      padding:0 20px;
    }

    .about-introduction-image {
      align-items: center;
      display: inherit;
      width: 40%;
    }
    .about-img{
        height:auto;
        margin-left: 20px;
    }
    .about-wrapper{
        margin:0 10%;
        padding:40px;
        bottom:180px;
    }

    .about-title{
        text-align: center;
        font-size: 28px;
    }
    .read-more{
        text-align: center;
        margin:0 auto;
        display: flex;
        justify-content: center;
        margin-top:4%;
    }
    .agents{
        display: flex;
        justify-content: center;
    }
    .agent-individual{
        width:40%;
        padding:4% 3% 0;
    }
    .agents-pic{
        width:100%;
        height:auto;
    }
    .agent-name{
        font-size:17px;
    }
    .profession{
        font-size:14px;
    }
    .social-media-icon{
        width: 22px;
    }
    .lower-section{
        margin-top:2%;
    }
    .about-p{
        font-size: 16px;
    }
    .service-item{
        font-size:16px;
        padding: 5px 0;
    }
    .service-final{
        font-size: 17px;
        font-weight: bold;
    }
    .about-second-title{
        position: relative;
        right:23%;
        margin-top: 30px;
    }
}
