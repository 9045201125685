.article{
    background-color: #f8f9fa;
    &__wrapper{
        padding:20px;
        min-height: 100vh;
    }
    &__main-img{
        height:300px;
        width:100%;
    }
    &__title, &__subtitle{
        text-align: left;
        color:#222222;
        font-weight: lighter;
    }
    &__title{
        line-height: 28px;
        font-size: 28px;
        padding:10px 0 10px;
        font-weight: bolder;
        border-bottom:1px solid #90c923;
        width:100%;
        margin:0 0 10px;
    }
    &__subtitle{
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    &__paragraph{
        color:#222222;
        padding:10px 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
        margin:0;
        
    }
    &__secondary-img{
        width:100%;
        height:auto;
        margin:10px 0;
    }

    @media(min-width:768px){
        &__wrapper{
            padding:40px;
        }
    }

    @media(min-width:1024px){
        &__main-img{
            height:400px;
            object-fit: cover;
            margin:15px 0;
        }
        &__wrapper{
            padding:40px 14%;
        }
        &__title{
            font-size: 36px;
            width:50%;
            padding-bottom: 15px;
        }
        &__subtitle{
            font-size: 24px;
        }
        &__secondary-img{
            height:450px;
            object-fit: cover;
            margin:15px 0;
        }
        &__footer{
            padding:10px 14% 40px;
        }
    }
}