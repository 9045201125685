.hamburger {
  &__link {
    text-align: center;
    padding: 0 10px;

    a {
      display: block;
      line-height: 40px;
      width: 100%;
      font-weight: bolder;
      font-size: 14px;
      color: #727376;
      cursor: pointer;

      .link-title {
        display: inline-block;
        vertical-align: middle;
        line-height: 1em;
      }
    }
  }

  &__link--menu {
    height: 60px;
    line-height: 60px;
    width: 100%;
    position: relative;
    right:30px;
    color: grey;
  }

  &__right-icon, &__left-icon {
    width: 20px;
    line-height: 50px;
    margin-top: 20px;
  }

  &__right-icon {
    float: right;
    opacity: .3;
  }

  &__left-icon {
    opacity: .3;
    position: relative;
    top:5px;
    right:10px;
  }
}
