.blog{
    background-color: #f8f9fa;
    &__all{
        border-bottom:1px solid #90c923;
        width:60%;
        margin:30px 0 0 20px;
    }
    &__title-color{
        color:#ffff;
    }
    &__wrapper{
        padding:20px;
    }
    &__container{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    &__poster{
        width:100%;
        height:190px;
    }
    &__card{
        background-color: #ffff;
        margin:20px 0;
        border:1px solid #90c923;
        box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
        border-radius: 10px;
        div{
            padding:0 20px;
        }
        button{
            background-color: #90c923;
            border:none;
            border-radius: 5px;
            color:#fff;
            font-weight: bolder;
            font-size: 14px;
            margin:10px 0 20px 20px;
            height:35px;
            cursor: pointer;
        }
    }
    &__title{
        font-size: 22px;
        line-height: 54px; 
        color: #222222;
        margin:0;
    }
    &__date{
        font-size: 12px;
        margin:0;
        line-height: 26px;
        color: #646464;
        padding-bottom: 10px;
    }
    &__text{
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin:0;
        color: #00183A;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media(min-width:768px){
        &__wrapper{
            padding:40px;
        }
        &__all{
            margin-left: 40px;
            padding-bottom: 10px;
            width:30%;
        }
    }
    @media(min-width:1024px){
        &__all{
            margin-left: 10%;
            padding-bottom: 10px;
            width:18%;
            margin-top: 40px;
            font-size: 28px;
        }
        &__wrapper{
           padding:20px 10% 0;
        }
        &__container{
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           flex-wrap: wrap;
        }
        &__card{
            width:46%;
            height:auto;
            cursor: pointer;
        }
        &__card div{
            padding:0 20px 0;
        }
        &__poster{
            height:250px;
        }
        &__banner{
            height:auto;
        }
        &__banner-heigth{
            height:350px;
        }
        &__banner-text{
            top:50px;
        }
}
}