.hamburger {
  &__logo{
    width:150px;
    height:44px;
    position: relative;
    top:10px;
    left:10px;
  }
  &__icon {
    width: 25px;
    border:1px solid #90c923;
    padding:5px 10px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__links {
    list-style: none;
    width: 100%;
    height:300px;
    z-index: 20;
    clear: both;
    overflow: hidden;
    top:0;
    left: 0;
    padding-left: 0;
    height: auto;
    margin-top: 0;
    background-color: #f8f9fa;
    border-right: solid 1px lightgrey;
    position: fixed;
    transform: translateY(-100%);
    transition: transform .3s;
    overflow-x: auto;

    &.active {
      transform: translateX(0);
    }
  }

  &__links--submenu {
    width: 100%;
    z-index: 21;
  }

  &__close-icon {
    display: none;
    position: absolute;
    width: 20px;
    right: 8%;
    top: 10px;
    z-index: 20;
    padding:10px;
    border:1px solid #90c923;

    &.active {
      display: block;
    }
  }
  .offer-search-mobile{
    height:40px;
    width:auto;
  }
  &__list-button{
    background-color: #90c923;
    border:none;
    margin:10px auto;
    display: flex;
    height:40px;
    align-items: center;
    border-radius: 3px;
    h5{
      color: #fff;
      font-size: 14px;
    }
  }
  .standard-link{
    cursor: pointer;
    text-decoration: none;
    color:initial;
  }

  @media(min-width:768px) {
    &__links{
      padding-bottom: 20px;
    }
  }

  @media(min-width:1024px) {
    display: none;
  };
}
