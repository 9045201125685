*, ::after, ::before {
    box-sizing: content-box;
}
.cookie-link{
    text-decoration: none;
    color:#ffff;
}
@media (min-width:1024px){
  .cookies {
    padding-left: 10%;
  }
}
