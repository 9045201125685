.request-form{
    font-family: Roboto;
}
.info-request{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 10% 0 0;
    font-family: Roboto;
}
.request-field{
    background-color: #f8f9fa;
    padding: 0 10px;
    width: 90%;
    height: 40px;
    margin-bottom: 20px!important;
    font-size:14px;
    line-height: 1.5;
    border-radius: 3px;
}
::placeholder{
    color: #a1a1a1;
    font-family: Roboto;
    font-size:14px;
}
textarea{
    height:auto!important;
    width:100%!important;
}
.consent{
    display: flex;
    flex-direction: row;
}
.request-checkbox{
    height:17px;
    width:17px;
}
.request-button{
  margin-top: 10px;
  width:90%;
  height:30px;
  font-family: Roboto;
}
.request-label{
    margin:20px 0 5px;
    font-weight: bolder;
    font-size: 14px;
}
.consent{
    margin-top: 20px;
}
.data-consent{
    font-size:14px;
    position: relative;
    top:3px;
    left: 5px;;
}
.request-dropdown{
    width:100%;
    .MuiNativeSelect-root{
        border:1px solid #ced4da;
        background-color: #f8f9fa;
        padding:5px;    
        border-radius: 5px;
    }
    .MuiInputBase-root{
        color:black;
        font-size: 16px;
        font-weight: 500;
    }
}
.request-form-whole{
    input[type=text], input[type=email], input[type=number], textarea, .contact-text-area{
        width: 100%;
        margin:5px 0;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        background-color: #f8f9fa;
        font-family: Roboto;
        color:#495057;
      }
      input[type=text], input[type=email], input[type=number]{
        height:40px;
      }
      input::placeholder, textarea::placeholder{
        color:#495057;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        padding-left: 5px;
      }
}

@media(min-width:768px){
    .info-request{
        margin-top:5%;
        font-size:18px;
    }
 
    ::placeholder{
        font-size:16px;
    }
    .info-request-second{
        margin-left:0 !important;
      
    }
    .consent{
        margin-top:3%;
    }
    .request-button{
        font-size:15px;
        padding: 10px 15px 10px 15px;
    }
}
@media(min-width:1024px){
    .request-form{
        width:100%;
        padding-right:40px;
    }
    .request-dropdown{
        width:108%;
        position: relative;
        right:20px;
    }
   .request-button{
       width:95%;
   }

}
